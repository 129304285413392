import React from 'react';
import { Row, Col } from 'antd'
import Styled from 'styled-components'
import { Link } from "gatsby"


const Texte = Styled.p`
    color: white;
    font-size: 16px;
`;

const Titre = Styled.h2`
    letter-spacing: 1px;
    font-weight: 600;
    text-transform: uppercase;
    height: 100%;
    color: white;
`;

const Li = Styled.li`
    a{
        color: white!important;
    }
`;

const Footer = () => {

    return (
        <div className="footer" style={{backgroundColor: '#004A8E', padding: "30px"}}>
            <Row className="container"  type="flex" justify="space-around" align="top">
                <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                    <img alt="" src="https://res.cloudinary.com/hq2sbosor/image/upload/w_150/v1546011902/logos/logo_v1.png" width="100"/><br/>
                    <Texte style={{textAlign: 'left'}}>Shalee, l'agent digital équestre.</Texte>
                </Col>
                <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                    <Titre>À propos</Titre>
                    <ul>
                        <Li><Link to="/tutoriels">Tutoriels</Link></Li>
                        <Li><Link to="/shalee">Histoire de Shalee</Link></Li>
                        <Li><Link to="/legal">Mentions légales</Link></Li>
                    </ul>
                </Col>
                <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                    <Titre>Nous contacter</Titre>
                    <ul>
                        <li>Service client</li>
                        <Li><Link to="/contact">Contact</Link></Li>
                    </ul>                
                </Col>
                <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                    <Titre>Télécharger</Titre>
                    <div >
                        <a href="https://itunes.apple.com/fr/app/shalee/id1414309393?mt=8" className="appstore-link">
                            <img alt="" src="https://yuka.io/wp-content/themes/fusion/images/appstore-badge.svg" />
                        </a><br/>
                        <a href="https://play.google.com/store/apps/details?id=com.tim0.shalee" className="playstore-link">
                            <img alt="" src="https://yuka.io/wp-content/themes/fusion/images/playstore-badge.svg" />
                        </a>
                    </div>                
                </Col>
            </Row>
        </div>
    );
};

export default Footer;