import React from 'react';
import Layout from '../components/layout'
import Footer from '../components/footer'
import { graphql, Link } from "gatsby"
import SEO from '../components/seo'
import { List, Row, Col } from 'antd';

import '../styles/global.scss'


const Tutoriels = ({ data }) => (
    <Layout position='top' currentPage='tutoriels'>
        <SEO title="Shalee, l'agent digital équestre" keywords={[`Shalee`,`Application`,`Mobile`,`Cheval`,`Chevaux`,`Club`,`Equestre`,`Equitation`,`Cavalier`]}/>
        <div style={{padding: '150px 0'}}>
            <Row className="container"  type="flex" justify="space-around" align="middle" gutter={50}>
                <Col>
                <h1 style={{textAlign:'center'}}>Tutoriels</h1>
                <List 
                    itemLayout='horizontal'
                    dataSource={data.allMarkdownRemark.edges}
                    renderItem={({ node }) => (
                        <List.Item id={node.id}>
                                    <List.Item.Meta
                                        title= {
                                        <span style={{ fontSize: '2rem'}}>
                                            <Link to={node.frontmatter.slug}>
                                                {node.frontmatter.title}
                                            </Link>
                                        </span>
                                        }
                                        description={
                                            <Link to={node.frontmatter.slug}>
                                                <p>
                                                {node.excerpt}
                                                </p>
                                            </Link>
                                        }
                                    />
                        </List.Item>)}/>
                </Col>
            </Row>
        </div>
        <Footer/>
    </Layout>
)

export const query = graphql`
  query {
    allMarkdownRemark( 
      sort: {fields: [frontmatter___date], order: ASC }
      filter: { frontmatter: {template : { eq: "tutoriel" } } }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "DD MMMM, YYYY")
            slug
          }
          excerpt
        }
      }
    }
  }`;

  export default Tutoriels;