import React, { Component } from 'react';
import Styled from 'styled-components'
import { Menu, Drawer, Button } from 'antd'
import { Link } from "gatsby"


import '../styles/layout.scss'

const Navbar = Styled.div`
    position: fixed;
    z-index: 100;
    width: 100%;
    background-color: white;
    border-bottom: 1px solid #e8e8e8;
`;

const Logo = Styled.h1`
    float: left;
    font-size: 35px;
    font-weight: bold;
    background-image: linear-gradient(to top left,#004a8e,#1482bf);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
`;

class BurgerButton extends Component {
    state = { visible: false, placement: 'right' };
  
    showDrawer = () => {
      this.setState({
        visible: true,
      });
    };
  
    onClose = () => {
      this.setState({
        visible: false,
      });
    };
  
    render() {
      return (
        <div className="burgerButton" style={{ float: 'right', height:"100%", marginTop: "0" }}>
          <Button type="primary" ghost="true" icon="menu-fold" size="large" onClick={this.showDrawer} style={{margin:"auto"}}/>
            <Drawer
              placement={this.state.placement}
              closable={false}
              onClose={this.onClose}
              visible={this.state.visible}
              >
              {this.props.children}
            </Drawer>
        </div>
      );
    }
  }

const Layout = ({ children, position, currentPage }) => (
    <div>
        <Navbar className={position}  style={{paddingLeft:"20px",paddingRight:"20px"}}>
            <div className='container'>
              <Link to="/"><Logo>Shalee</Logo></Link>
                <Menu
                    className="menu"
                    mode="horizontal"
                    defaultSelectedKeys={[currentPage]}>
                        <Menu.Item key="home"><Link to="/">Découverte</Link></Menu.Item>
                        <Menu.Item key="shalee"><Link to="/shalee">Pourquoi Shalee</Link></Menu.Item>
                        <Menu.Item key="cavalier"><Link to="/cavalier">Cavalier</Link></Menu.Item>
                        <Menu.Item key="club"><Link to="/club">Club équestre</Link></Menu.Item>
                </Menu>
                <BurgerButton style={{width:'auto'}}>
                    <Menu
                        mode="inline"
                        defaultSelectedKeys={[currentPage]}
                    >
                        <Menu.Item key="logo" style={{height:"100px", textAlign:'center'}}><Link to="/"><img alt="shalee" src="https://res.cloudinary.com/hq2sbosor/image/upload/v1547861384/logos/logo_RECTO_V2.png" width="80"/></Link></Menu.Item>
                        <Menu.Item key="home"><Link to="/">Découverte</Link></Menu.Item>
                        <Menu.Item key="shalee"><Link to="/shalee">Pourquoi Shalee</Link></Menu.Item>
                        <Menu.Item key="cavalier"><Link to="/cavalier">Cavalier</Link></Menu.Item>
                        <Menu.Item key="club"><Link to="/club">Club équestre</Link></Menu.Item>
                    </Menu>
                </BurgerButton>
            </div>
        </Navbar>  
        {children}
    </div>
);

export default Layout;
